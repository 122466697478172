import React, { Suspense, useContext } from 'react';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import { Link, useMatch } from 'react-router-dom';
import { Bars3Icon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { HelpCenterWidget } from 'app/components/HelpCenter/HelpCenterWidget';
import { ProjectsSelector } from 'app/components/ProjectsSelector/ProjectsSelector';
import { TrialBar } from 'app/components/SubscriptionPlanFeatures/Trial/TrialBar';
import { LayoutContext } from 'app/contexts/Layout.context';
import { useDistanceToBottom } from 'app/hooks/useDistanceToBottom';
import { useProject } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import { usePostLoginRedirect } from '../../../hooks/usePostLoginRedirect';
import { QuickNewButton } from '../../QuickNewButton/QuickNewButton';
import ShapeLogo from '../ShapeLogo/ShapeLogo';
import { BackNavigationButton } from './components/BackNavigationButton';
import { ForwardNavigationButton } from './components/ForwardNavigationButton';
import { Notifications } from './components/Notifications';
import { ProfileMenu } from './components/ProfileMenu';
import { ProjectSetupProgressNavbar } from './components/ProjectSetupProgressNavbar/ProjectSetupProgressNavbar';
import { ReloadButton } from './components/ReloadButton';
import { SearchField } from './components/SearchField';

export const Navbar = () => {
  const { defaultProject } = useCurrentUser();
  const {
    layoutConfig: { showSideDrawerMenu, showBackButton, showTitle, showProjectList, title },
    openDrawer,
  } = useContext(LayoutContext);
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const inNotificationRoute = useMatch('/notifications');
  const { ref: parentRef, distanceToBottom } = useDistanceToBottom<HTMLDivElement>();
  const match = projectRoute || notificationRoute;
  const isProjectRoute = !!match?.params.projectId;
  const currentProjectId = match?.params?.projectId ?? defaultProject;
  const { data: currentProject } = useProject(
    (inNotificationRoute ? undefined : currentProjectId)!,
    {
      query: { enabled: isProjectRoute && !!currentProjectId },
    }
  );
  const isQuickNewButtonVisible = currentProjectId && isLargeScreen;
  const { postLoginPath } = usePostLoginRedirect();
  const landingPageLink = currentProject
    ? `/projects/${currentProject?.id}/timeline`
    : postLoginPath;

  const renderDrawerMenu = () => (
    <div className="block md:hidden">
      <Bars3Icon
        data-testid="res-drawer-btn"
        onClick={openDrawer}
        className="cursor-pointer h-6 w-6"
      />
    </div>
  );

  return (
    <div
      data-cy="navbar"
      data-mode="dark"
      className="sticky shadow-none whitespace-nowrap z-navigation"
      ref={parentRef}
    >
      {currentProject && (
        <TrialBar projectId={currentProject?.id} teamId={currentProject?.currentTeamId} />
      )}
      <div className="flex justify-between bg-gray-800 text-white px-3 md:px-8 min-h-[56px] md:min-h-[64px]">
        <div className="flex items-center w-full gap-x-2 md:gap-x-4 min-w-0">
          {showSideDrawerMenu && renderDrawerMenu()}
          {showBackButton && currentProjectId && <BackNavigationButton />}
          <div
            className={classNames('shrink-0 w-auto md:mr-2', {
              'block': !showSideDrawerMenu,
              'hidden md:block': showSideDrawerMenu,
            })}
          >
            <Link to={landingPageLink}>
              <ShapeLogo whiteVersion withLettering />
            </Link>
          </div>
          <div className="hidden md:flex">
            {currentProjectId && (
              <>
                <BackNavigationButton />
                <ForwardNavigationButton />
              </>
            )}
            <ReloadButton />
          </div>
          {showProjectList && (
            <div className="relative flex w-full overflow-hidden md:hidden">
              <ProjectsSelector
                currentProject={currentProject}
                isExpanded
                className="text-white hover:bg-gray-900"
              />
            </div>
          )}
          {showTitle && title && (
            <h4 className="relative inline-block overflow-hidden text-ellipsis whitespace-nowrap text-left">
              {title}
            </h4>
          )}
          <SearchField />
        </div>

        <div className="flex items-center">
          {currentProjectId && currentProject && isProjectRoute && (
            <div className="hidden w-40 mr-8 lg:block">
              <ErrorBoundary fallback={<>{null}</>}>
                <Suspense fallback={null}>
                  <ProjectSetupProgressNavbar projectId={currentProjectId} />
                </Suspense>
              </ErrorBoundary>
            </div>
          )}
          {isQuickNewButtonVisible && <QuickNewButton projectId={currentProjectId} />}
          <HelpCenterWidget />
          <Notifications distanceToBottomOfScreen={distanceToBottom} />
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
};
