import React, { forwardRef, useEffect, useState } from 'react';
import { useMessageGetter } from '@messageformat/react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import type { ProjectSchema } from '@shape-construction/api/model';
import { InputAdornment, Select, SelectRootProps } from '@shape-construction/arch-ui';
import { UserAvatar } from '@shape-construction/arch-ui/src/Avatar';
import { useConstructionRoles } from 'app/components/People/constructionRoles/hooks/useConstructionRoles';
import { PersonItem } from 'app/components/PersonItem/PersonItem';
import { NoSearchResults } from 'app/components/Search/NoSearchResults';
import { SearchLoading } from 'app/components/Search/SearchLoading';
import { useProjectPeople } from 'app/queries/projects/people';
import { useCurrentUser } from 'app/queries/users/users';

type Params = { projectId: ProjectSchema['id'] };
type AuthorSelectProps = SelectRootProps<string[], true> & {};

const initialValue: string[] = [];

export const AuthorSelect = forwardRef<React.ElementRef<typeof Select.Root>, AuthorSelectProps>(
  ({ value = initialValue, onChange }, ref) => {
    const currentUser = useCurrentUser();
    const { projectId } = useParams() as Params;
    const messageGetter = useMessageGetter('');
    const [checked, setChecked] = useState(value);
    const [personTerm, setPersonTerm] = useState('');
    const { constructionRoles, isLoading: isLoadingRoles } = useConstructionRoles();
    const { data: searchPeople, isLoading } = useProjectPeople(projectId, { search: personTerm });
    const { data: selectedPeople = [] } = useProjectPeople(projectId, undefined, {
      query: {
        select: (data) => {
          return checked.map((personId) => data?.find(({ id }) => personId === String(id))!) || [];
        },
      },
    });

    const handleOnChange = (values: string[]) => {
      onChange?.(values.map(String));
    };

    const renderContent = () => {
      if (isLoading || isLoadingRoles) {
        return (
          <Select.PanelSection className="flex-1 w-full flex flex-col justify-center">
            <SearchLoading />
          </Select.PanelSection>
        );
      }

      if (searchPeople?.length === 0) {
        return (
          <Select.PanelSection className="flex-1 w-full flex flex-col items-center justify-center">
            <NoSearchResults />
          </Select.PanelSection>
        );
      }

      return searchPeople?.map((person) => (
        <Select.Option key={person.id} value={person.id}>
          <PersonItem
            avatar={<UserAvatar user={person.user} size="md" />}
            primaryLabel={person.user.name}
            secondaryLabel={person.team.displayName || ''}
            inlineLabel={
              person.user.id === currentUser?.id
                ? messageGetter('search.people.currentUser')
                : undefined
            }
            inlineBadge={
              person.constructionRole ? constructionRoles[person.constructionRole].label : null
            }
          />
        </Select.Option>
      ));
    };

    useEffect(() => {
      if (value) setChecked(value);
    }, [value]);

    return (
      <Select.Root
        ref={ref}
        multiple
        by={(a, b) => String(a) === String(b)}
        value={checked}
        onChange={handleOnChange}
        onClose={() => setPersonTerm('')}
      >
        <Select.Trigger
          as="div"
          variant="bordered"
          size="sm"
          startAdornment={
            <InputAdornment>{messageGetter('projectGallery.filters.author.label')}</InputAdornment>
          }
        >
          <Select.MultipleValue value={value} condensed />
        </Select.Trigger>

        <Select.ResponsivePanel className="md:min-w-[340px] md:h-[40vh] h-[80vh]">
          <Select.PanelSection className="px-4 py-5 md:hidden">
            <h1 className="text-base leading-6 font-medium">Filter by author</h1>
          </Select.PanelSection>

          <Select.PanelSection
            className={classNames(
              'max-h-[60px] overflow-y-auto md:hidden',
              checked.length === 0 && 'hidden'
            )}
          >
            <Select.MultipleValue
              value={selectedPeople}
              onRemove={(person) =>
                setChecked((state) => state.filter((personId) => personId !== String(person.id)))
              }
              onRemoveAll={() => setChecked([])}
              displayValue={(person) => person.user.name}
            />
          </Select.PanelSection>

          <Select.PanelSection>
            <Select.Search
              placeholder={messageGetter('search.people.placeholder')}
              onChange={(event) => setPersonTerm(event.target.value)}
            />
          </Select.PanelSection>
          <Select.Options className="flex-1">{renderContent()}</Select.Options>
        </Select.ResponsivePanel>
      </Select.Root>
    );
  }
);

AuthorSelect.displayName = 'AuthorSelect';
