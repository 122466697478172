import React from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useQueryClient } from '@tanstack/react-query';
import { getGetApiProductToursProductTourKeyQueryKey } from '@shape-construction/api/api';
import { Alert, Button } from '@shape-construction/arch-ui';
import { PlayCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { useSendAnalyticsEvent } from 'app/queries/analytics/analytics';
import { useProductTourUpdate } from 'app/queries/productTours/productTours';
import { useProductTourPopover } from '../hooks/useProductTourPopover';

interface ProductTourAlertProps {
  openStorylaneModal: () => void;
  productTourKey: string;
  productTourName: string | JSX.Element;
  showCloseButton: boolean;
  displayInRow: boolean;
}

export const ProductTourAlert: React.FC<ProductTourAlertProps> = ({
  openStorylaneModal,
  productTourKey,
  productTourName,
  showCloseButton,
  displayInRow,
}) => {
  const queryClient = useQueryClient();
  const messages = useMessageGetter('productTour.alert');
  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const { showProductTourPopover, openProductTourPopover } = useProductTourPopover();

  const { mutate: updateProductTour } = useProductTourUpdate();
  const { mutate: sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleCloseAlert = () => {
    if (showProductTourPopover) openProductTourPopover();

    sendAnalyticsEvent({ event_name: 'tour_dismissed', properties: { tour_key: productTourKey } });
    updateProductTour(
      { productTourKey, data: { dismissed_at: new Date().toISOString() } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getGetApiProductToursProductTourKeyQueryKey(productTourKey),
          });
        },
      }
    );
  };

  const isFullWidthLayout = isLargeScreen || displayInRow;
  const containerClasses = isFullWidthLayout ? '' : 'm-2';

  return (
    <div className={containerClasses}>
      <Alert
        color="discovery"
        emphasis="bold"
        rounded={!isFullWidthLayout}
        customIcon={<PlayCircleIcon className="h-5 w-5 text-white" />}
        onClose={showCloseButton ? handleCloseAlert : undefined}
        justifyContent="start"
        displayInRow={displayInRow}
      >
        <Alert.Message>{messages('message', { productTourName })}</Alert.Message>
        <Alert.Actions>
          <Button variant="outlined" color="white" size="xs" onClick={openStorylaneModal}>
            {messages('viewTourCTA')}
          </Button>
        </Alert.Actions>
      </Alert>
    </div>
  );
};
