import React from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import {
  XCircleIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  MegaphoneIcon,
} from '../Icons/solid';
import { AlertActions } from './components/AlertActions';
import { AlertMessage } from './components/AlertMessage';
import { AlertCloseButton } from './components/AlertCloseButton';
import { AlertColor, AlertFormat, AlertEmphasis, AlertJustifyContent } from './alert-config';

export const colorClassesMap: Record<
  NonNullable<AlertColor>,
  Record<NonNullable<AlertEmphasis>, string>
> = {
  primary: {
    subtle: 'bg-indigo-50 border-indigo-100 text-indigo-700',
    bold: 'bg-indigo-500 border-0 text-white',
  },
  secondary: {
    subtle: 'bg-gray-50 border-gray-100 text-gray-700',
    bold: 'bg-gray-700 border-0 text-white',
  },
  success: {
    subtle: 'bg-green-50 border-green-100 text-green-700',
    bold: 'bg-green-500 border-0 text-white',
  },
  warning: {
    subtle: 'bg-yellow-50 border-yellow-100 text-yellow-700',
    bold: 'bg-yellow-500 border-0 text-white',
  },
  danger: {
    subtle: 'bg-red-50 border-red-100 text-red-700',
    bold: 'bg-red-600 border-0 text-white',
  },
  discovery: {
    subtle: 'bg-purple-50 border-purple-200 text-purple-700',
    bold: 'bg-purple-500 border-0 text-white',
  },
};

type Components = {
  Message: typeof AlertMessage;
  Actions: typeof AlertActions;
};
export interface AlertProps {
  children: React.ReactNode;
  /**
   * Type of context for this component.
   * It changes the icon and color scheme
   */
  color: AlertColor;
  /**
   * Alert emphasis (subtle or bold)
   */
  emphasis?: AlertEmphasis;
  /**
   * Alert format (inline or floating)
   */
  format?: AlertFormat;
  /**
   * Override icon
   */
  customIcon?: React.ReactNode;
  /**
   * Called when the Alert is dismissed.
   */
  onClose?: () => void;
  /**
   * Adds rounded borders. Usually used when alert is not top-aligned
   */
  rounded?: boolean;
  /**
   * Displays alert in a row, on mobile view
   */
  displayInRow?: boolean;
  /**
   * Overrides message position in the container
   */
  justifyContent?: AlertJustifyContent;
}

const icons = {
  primary: {
    subtle: <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />,
    bold: <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  secondary: {
    subtle: <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />,
    bold: <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  success: {
    subtle: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
    bold: <CheckCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  warning: {
    subtle: <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
    bold: <ExclamationTriangleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  danger: {
    subtle: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
    bold: <XCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  discovery: {
    subtle: <MegaphoneIcon className="h-5 w-5 text-purple-500" aria-hidden="true" />,
    bold: <MegaphoneIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
};

export const Alert: React.FC<AlertProps> & Components = ({
  children,
  color,
  emphasis = 'subtle',
  format = 'inline',
  onClose,
  rounded = true,
  customIcon,
  displayInRow = false,
  justifyContent,
}) => {
  const iconElement = customIcon || icons[color][emphasis];

  return (
    <div
      className={classNames('@container whitespace-normal', {
        'fixed px-4 left-0 bottom-6 w-screen z-modal': format === 'floating',
      })}
    >
      <div
        data-testid={`alert-${color}`}
        role="alert"
        className={twMerge(
          classNames('px-3 py-2 border relative flex flex-row', colorClassesMap[color][emphasis], {
            'max-w-5xl m-auto shadow-lg': format === 'floating',
            'rounded-md': rounded,
          })
        )}
      >
        <div
          className={classNames('flex-1 flex items-start @md:items-center', {
            '@lg:justify-center': format === 'inline',
            'justify-center': justifyContent === 'center',
            'items-center': displayInRow,
            '@lg:justify-start': justifyContent === 'start',
          })}
        >
          <div className="shrink-0">{iconElement}</div>

          <div
            className={twMerge(
              classNames(
                'flex flex-col @md:flex-row @md:items-center @md:justify-between ml-3 text-sm w-full gap-3',
                {
                  '@lg:justify-center @lg:w-auto':
                    format === 'inline' && justifyContent !== 'start',
                  'justify-center w-auto': justifyContent === 'center',
                  'flex-row items-center justify-between mr-0': displayInRow,
                  '@justify-start': justifyContent === 'start',
                }
              )
            )}
          >
            {children}
          </div>
        </div>
        {onClose && <AlertCloseButton onClose={onClose} color={color} emphasis={emphasis} />}
      </div>
    </div>
  );
};

Alert.Message = AlertMessage;
Alert.Actions = AlertActions;
