import * as React from 'react';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocalStorage } from '@shape-construction/hooks';
import { RootState } from 'app/store';
import { useCookieConsent } from '../analytics/hooks/useCookieConsent';
import {
  closeInstallDialog as closeInstallDialogAction,
  openInstallDialog as openInstallDialogAction,
} from '../store/install-dialog/installDialogSlice';
import { useUserAgent } from './useUserAgent';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

type InstallContext = {
  isInstallSupported: boolean;
  isInStandaloneMode: boolean;
  isSafariOnNonDesktop: boolean;
  isInstallDialogOpen: boolean; // legacy
  hasCookieConsent: boolean; // legacy
  hasOpenedOnce: boolean; // legacy
  openInstallDialog: () => void; // legacy
  closeInstallDialog: () => void; // legacy
  prompt: BeforeInstallPromptEvent | null;
  promptToInstall: () => void;
  isInstallAppPromptClosed: boolean;
  commitInstallAppPromptClosed: () => void;
  restoreInstallAppPromptClosed: () => void;
};

const InstallAppContext = createContext<InstallContext | undefined>(undefined);

const useInstallAppPrompt = (): InstallContext => {
  const navigator = window.navigator as any;
  const { safari, touchDevice } = useUserAgent();
  const isSafariOnNonDesktop = safari && touchDevice;

  // Legacy, Redux to be deprecated in favour of the local storage
  const dispatch = useDispatch();
  // Legacy, new popover is non-blocking, no need to wait for consent
  const { consentData } = useCookieConsent();
  // Legacy, to be removed with install-shape-popover FF
  const isInstallDialogOpen = useSelector((state: RootState) => state.installDialog).isOpen;
  // Legacy
  const [hasOpenedOnce, setHasOpenedOnce] = useState<boolean>(false);

  const [prompt, setPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState<boolean>(
    ('standalone' in navigator && navigator.standalone) ||
      (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
  );

  const [installAppPromptClosed, setInstallAppPromptClosed] = useLocalStorage(
    'install_app_prompt_closed',
    false
  );

  const commitInstallAppPromptClosed = useCallback(() => {
    setInstallAppPromptClosed(true);
  }, [setInstallAppPromptClosed]);

  const restoreInstallAppPromptClosed = useCallback(() => {
    return Boolean(installAppPromptClosed);
  }, [installAppPromptClosed]);

  // Legacy, to be removed with install-shape-popover FF
  const openInstallDialog = React.useCallback(() => {
    dispatch(openInstallDialogAction());
  }, [dispatch]);

  // Legacy, to be removed with install-shape-popover FF
  const closeInstallDialog = React.useCallback(() => {
    setHasOpenedOnce(true);
    dispatch(closeInstallDialogAction());
  }, [dispatch]);

  useEffect(() => {
    const installAppListener = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setPrompt(event);
    };

    const appInstalledListener = () => {
      setIsInStandaloneMode(true);
    };

    window.addEventListener('beforeinstallprompt', installAppListener as () => void);
    window.addEventListener('appinstalled', appInstalledListener);

    return () => {
      window.removeEventListener('beforeinstallprompt', installAppListener as () => void);
      window.removeEventListener('appinstalled', appInstalledListener);
    };
  }, []);

  const promptToInstall = () => {
    if (!prompt) {
      return Promise.reject(
        new Error('The browser must emit the "beforeinstallprompt" event before installing')
      );
    }

    return prompt.prompt();
  };

  return {
    isInstallSupported: prompt !== null,
    isInStandaloneMode,
    isSafariOnNonDesktop,
    isInstallDialogOpen, // legacy
    hasCookieConsent: !!consentData, // legacy
    hasOpenedOnce, // legacy
    openInstallDialog, // legacy
    closeInstallDialog, // legacy
    prompt,
    promptToInstall,
    isInstallAppPromptClosed: Boolean(installAppPromptClosed),
    commitInstallAppPromptClosed,
    restoreInstallAppPromptClosed,
  };
};

export const useInstallApp = (): InstallContext => {
  const context = useContext(InstallAppContext);

  if (!context) {
    throw new Error('useInstallApp() must be used within an InstallAppProvider');
  }

  return context;
};

export const InstallAppProvider = ({ children }: { children: ReactNode }) => (
  <InstallAppContext.Provider value={useInstallAppPrompt()}>{children}</InstallAppContext.Provider>
);
