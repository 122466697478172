import React, { useContext, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useMatch } from 'react-router-dom';
import { breakpoints } from '@shape-construction/arch-ui/src/utils/breakpoints';
import { useMediaQuery } from '@shape-construction/hooks';
import { LayoutContext } from 'app/contexts/Layout.context';
import { getNotificationsOverviewQueryOptions } from 'app/queries/notifications/notifications';
import { useProject } from 'app/queries/projects/projects';
import { useCurrentUser } from 'app/queries/users/users';
import { SideDrawerContent } from './SideDrawerContent';

const ResponsiveSideDrawer = () => {
  const projectRoute = useMatch('/projects/:projectId/*');
  const notificationRoute = useMatch('/notifications/projects/:projectId');
  const match = projectRoute || notificationRoute;
  const currentProjectId = match?.params.projectId;

  const isLargeScreen = useMediaQuery(breakpoints.up('md'));
  const shouldExpandDrawer = useMediaQuery(breakpoints.up('lg'));
  const {
    drawerOpen: mobileOpen,
    closeDrawer,
    drawerExpanded,
    toggleDrawerExpanded,
    layoutConfig: { showSideDrawer },
  } = useContext(LayoutContext);

  const currentUser = useCurrentUser();
  const { data: notificationOverview } = useQuery(getNotificationsOverviewQueryOptions());
  const { data: currentProject } = useProject(currentProjectId!);

  useEffect(() => {
    if (drawerExpanded === undefined && shouldExpandDrawer) {
      toggleDrawerExpanded(true);
    }
    if (!shouldExpandDrawer) {
      toggleDrawerExpanded(false);
    }

    if (!shouldExpandDrawer) toggleDrawerExpanded(false);
  }, [toggleDrawerExpanded, currentUser, drawerExpanded, shouldExpandDrawer]);

  const handleCloseDrawer = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    const keyboardEvent = event as React.KeyboardEvent<HTMLElement>;

    if (
      event &&
      event.type === 'keydown' &&
      (keyboardEvent.key === 'Tab' || keyboardEvent.key === 'Shift')
    ) {
      return;
    }
    closeDrawer();
  };

  if (!showSideDrawer) return null;

  return (
    <Drawer
      data-testid="res-side-drawer"
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      open={mobileOpen}
      onClose={handleCloseDrawer}
      PaperProps={
        isLargeScreen
          ? {
              style: { position: 'relative', zIndex: 10 },
              classes: {
                root: classNames({
                  'w-drawer-expanded': drawerExpanded,
                  'w-drawer-collapsed': !drawerExpanded,
                }),
              },
            }
          : {
              style: { width: '320px' },
            }
      }
      // Better open performance on mobile.
      ModalProps={{ keepMounted: true }}
      className="h-full print:hidden"
    >
      <SideDrawerContent
        drawerExpanded={drawerExpanded || (!isLargeScreen && mobileOpen)}
        currentUser={currentUser}
        notificationOverview={notificationOverview}
        closeDrawer={handleCloseDrawer}
        currentProject={currentProject}
        toggleDrawerExpanded={toggleDrawerExpanded}
      />
    </Drawer>
  );
};

export { ResponsiveSideDrawer };
